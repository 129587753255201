var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.usercategory=='slave')?_c('div',{staticStyle:{"height":"40px","width":"100%"}},[_c('Button',{staticStyle:{"margin-top":"3px","float":"left"},attrs:{"type":"primary"},on:{"click":_vm.addmodel}},[_vm._v("添加新车型参数")])],1):_vm._e(),_c('div',{staticStyle:{"padding-top":"10px"}},[_c('Table',{attrs:{"columns":_vm.tblcolumns,"data":_vm.tbldata},scopedSlots:_vm._u([{key:"modelno",fn:function({ row }){return [_c('strong',[_vm._v(_vm._s(row.modelno))])]}},{key:"tuzhi",fn:function({ row, index }){return [_c('div',{staticClass:"opbar"},[_c('Button',{attrs:{"disabled":row.tuzhiurl==null,"type":"success","size":"small","ghost":""},on:{"click":function($event){return _vm.viewfile(row.tuzhiurl)}}},[_vm._v("查看")]),(_vm.usercategory=='slave')?_c('Upload',{attrs:{"action":"/api/params/files/upload","data":{
                            companyid: row.companyid,
                            parentid: row.id,
                            modelno:row.modelno,
                            bindfile: 100
                        },"on-success":_vm.handleSuccess,"before-upload":_vm.beforeUpload,"on-error":_vm.handleFailed,"show-upload-list":false}},[(row.tuzhiurl==null)?_c('Button',{attrs:{"type":"error","size":"small","ghost":""}},[_vm._v("上传")]):_c('Button',{attrs:{"type":"error","size":"small","ghost":""}},[_vm._v("重新上传")])],1):_vm._e()],1)]}},{key:"fcg",fn:function({ row,index }){return [_c('div',{staticClass:"opbar"},[_c('Button',{attrs:{"disabled":row.fcgurl==null,"type":"success","size":"small","ghost":""},on:{"click":function($event){return _vm.viewfile(row.fcgurl)}}},[_vm._v("查看")]),(_vm.usercategory=='slave')?_c('Upload',{attrs:{"action":"/api/params/files/upload","data":{
                            companyid: row.companyid,
                            parentid: row.id,
                            modelno:row.modelno,
                            bindfile: 101
                        },"on-success":_vm.handleSuccess,"before-upload":_vm.beforeUpload,"on-error":_vm.handleFailed,"show-upload-list":false}},[(row.fcgurl==null)?_c('Button',{attrs:{"type":"error","size":"small","ghost":""}},[_vm._v("上传")]):_c('Button',{attrs:{"type":"error","size":"small","ghost":""}},[_vm._v("重新上传")])],1):_vm._e()],1)]}},{key:"suppliers",fn:function({ row, index }){return [_c('Button',{attrs:{"type":"warning","ghost":"","size":"small"},on:{"click":function($event){return _vm.viewsuppliers(row.id)}}},[_vm._v("查看")])]}},{key:"action",fn:function({ row, index }){return [_c('Button',{staticStyle:{"margin-left":"15px"},attrs:{"type":"success","size":"small"},on:{"click":function($event){return _vm.view(index)}}},[_vm._v("查看")]),(_vm.usercategory=='slave')?_c('Button',{staticStyle:{"margin-left":"15px"},attrs:{"type":"error","size":"small"},on:{"click":function($event){return _vm.remove(index)}}},[_vm._v("删除")]):_vm._e(),(_vm.usercategory=='slave')?_c('Button',{staticStyle:{"margin-left":"15px"},attrs:{"type":"info","size":"small","ghost":""},on:{"click":function($event){return _vm.modify(index)}}},[_vm._v("修改")]):_vm._e()]}}])})],1),_c('Modal',{attrs:{"title":"删除车型数据"},on:{"on-ok":_vm.confirmDelete},model:{value:(_vm.showdeleteconfirm),callback:function ($$v) {_vm.showdeleteconfirm=$$v},expression:"showdeleteconfirm"}},[_vm._v("确定要删除吗？")]),_c('Modal',{attrs:{"closable":false,"footer-hide":true,"mask-closable":false},model:{value:(_vm.showloading),callback:function ($$v) {_vm.showloading=$$v},expression:"showloading"}},[_c('div',{staticStyle:{"display":"inline-block","width":"100%","height":"100px","position":"relative","border":"1px solid #eee"}},[_c('Spin',{attrs:{"fix":""}},[_c('Icon',{staticClass:"demo-spin-icon-load",attrs:{"type":"ios-loading","size":"18"}}),_c('div',[_vm._v("正在上传文件，请稍后...")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }