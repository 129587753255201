<template>
<div >
        <div style="height:40px;width:100%;"  v-if="usercategory=='slave'">
            <Button type="primary"  style="margin-top:3px;float:left" @click="addmodel">添加新车型参数</Button>
        </div>
        <!-- 账号列表-->
        <div style="padding-top:10px">
            <Table :columns="tblcolumns" :data="tbldata">
            <template slot-scope="{ row }" slot="modelno">
                <strong>{{ row.modelno }}</strong>
            </template>
            <template slot-scope="{ row, index }" slot="tuzhi">
                <div class="opbar">
                    <Button :disabled="row.tuzhiurl==null" type="success" size="small" ghost @click="viewfile(row.tuzhiurl)" >查看</Button>
                    <Upload
                        v-if="usercategory=='slave'"
                        action="/api/params/files/upload"
                        :data= "{
                            companyid: row.companyid,
                            parentid: row.id,
                            modelno:row.modelno,
                            bindfile: 100
                        }"
                        :on-success="handleSuccess"
                        :before-upload="beforeUpload"
                        :on-error="handleFailed"
                        :show-upload-list=false
                        >
                        <Button v-if="row.tuzhiurl==null" type="error" size="small" ghost >上传</Button>
                        <Button v-else type="error" size="small" ghost >重新上传</Button>
                    </Upload>
                </div>
            </template>
            <template slot-scope="{ row,index }" slot="fcg">
                <div class="opbar">
                    <Button :disabled="row.fcgurl==null" type="success" size="small" ghost @click="viewfile(row.fcgurl)" >查看</Button>
                    <Upload
                        v-if="usercategory=='slave'"
                        action="/api/params/files/upload"
                        :data= "{
                            companyid: row.companyid,
                            parentid: row.id,
                            modelno:row.modelno,
                            bindfile: 101
                        }"
                        :on-success="handleSuccess"
                        :before-upload="beforeUpload"
                        :on-error="handleFailed"
                        :show-upload-list=false
                        >
                        <Button v-if="row.fcgurl==null" type="error" size="small" ghost >上传</Button>
                        <Button v-else type="error" size="small" ghost >重新上传</Button>
                    </Upload>
                </div>
            </template>
            <template slot-scope="{ row, index }" slot="suppliers" >
                <Button type="warning" ghost size="small" @click="viewsuppliers(row.id)">查看</Button>
            </template>
            <template slot-scope="{ row, index }" slot="action" >
                <Button type="success" size="small" @click="view(index)" style="margin-left: 15px">查看</Button>
                <Button type="error" size="small" @click="remove(index)" style="margin-left: 15px"  v-if="usercategory=='slave'">删除</Button>
                <Button  type="info" size="small" ghost @click="modify(index)" style="margin-left: 15px"  v-if="usercategory=='slave'">修改</Button>
            </template>
            </Table>
        </div>
        <Modal v-model="showdeleteconfirm" title="删除车型数据" @on-ok="confirmDelete">确定要删除吗？</Modal>
        <Modal v-model="showloading" 
        :closable="false"
        :footer-hide="true"
        :mask-closable="false"
        >
           <div style="display: inline-block;
                   width: 100%;
                   height: 100px;
                   position: relative;
                   border: 1px solid #eee;">
               <Spin fix>
                   <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                   <div>正在上传文件，请稍后...</div>
               </Spin>
           </div>
       </Modal>
    </div>
</template>

<script>
export default {
    name:'ziliao',
    data () {
            return {
                companyid: this._self.$root.$store.state.LogonInfo.companyid,
                usercategory: this._self.$root.$store.state.LogonInfo.userCategory,
                showeditmodel:false,
                editmodeldata:null,
                showdeleteconfirm:false,
                showloading:false,
                deleteIndex:null,
                addFormInline:{
                    companyid: this._self.$root.$store.state.LogonInfo.companyid,
                    parentid:null,
                    filelist:[]
                },
                tblcolumns: [
                    {
                        type: 'index',
                        width: 60,
                        align: 'center'
                    },
                    {
                        title: '产品型号',
                        slot: 'modelno',
                    },      
                    {
                        title: '图纸',
                        slot: 'tuzhi',
                    },    
                    {
                        title: '防篡改',
                        slot: 'fcg',
                    },  
                    {
                        title: '合格供方目录',
                        slot: 'suppliers',
                    }, 
                    {
                        title: '操作',
                        slot: 'action',
                        fixed:"right",
                        align: 'center'
                    }
                    
                ],
                tbldata: []
            }
        },
        mounted (){
            this.handleQuery()
        },
        methods: {
            handleQuery() {
                this.$axios({
                    method:'post',
                    url:"/api/params/model/list",
                    data:{companyid:this._self.$root.$store.state.LogonInfo.companyid},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        console.log(res.data)
                        var rspdata = res.data.data
                        this.tbldata =rspdata
                        //this.$Message.success({content:res.data.msg, duration:3})
                        this.$store.commit('changeModelListInfo',this.tbldata)
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));   
            },
            addmodel(){
                let companycode = this.$route.params.companyCode
                let path = '/business/depart/'+companycode+'/checkdept/ziliaoAdd'
                console.log(path)
                console.log(this.$router)
                this.$router.push(path)
                console.log(this.$router)
            },
            modify (index) {
                let companycode = this.$route.params.companyCode
                let path = '/business/depart/'+companycode+'/checkdept/ziliaoAdd'
                this.$router.push({path: path,query:{id:this.tbldata[index].id,action:'modify'}})
            },
            view (index){
                let companycode = this.$route.params.companyCode
                let path = '/business/depart/'+companycode+'/checkdept/ziliaoAdd'
                this.$router.push({path: path,query:{id:this.tbldata[index].id,action:'view'}})
            },
            viewfile (url){
                let openurl = window.location.protocol+"//"+window.location.host+url
                console.log(openurl)
                window.open(openurl, '_blank');
            },
            viewsuppliers (id){
                this.$router.push({path:'/business/depart/'+this.$route.params.companyCode+'/checkdept/supplier', query:{modelid: id}})
            },
            confirmDelete(){
                this.$axios({
                    method:'post',
                    url:"/api/params/model/delete",
                    data:{id:this.tbldata[this.deleteIndex].id},
                    headers:{'Content-Type': 'application/x-www-form-urlencoded'},
                    transformRequest: function(obj) {
                        var str = [];
                        for(var p in obj){
                            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
                        }
                        return str.join("&");
                    }
                }).then(function(res){
                    if(res.data.resultCode ==0){
                        this.$Message.success({content:res.data.msg, duration:3})
                        this.handleQuery()
                    }
                    else{
                        this.$Message.error({content:res.data.data.msg, duration:3})
                    }
                }.bind(this));      
            },
            remove (index) {
                this.showdeleteconfirm = true
                this.deleteIndex = index
            },
            onEditClose(){
                this.showeditmodel =false
            },
            onSubmmitOver(){
                this.showeditmodel =false
                this.handleQuery()
            },
            handleSuccess(response, file, fileList){
                //this.addFormInline.filepath = response.data.filepath
                //this.addFormInline.filename = response.data.originfilename
                this.showloading = false
                this.$Message.success({content:"文件上传成功", duration:3})
                this.handleQuery()
                console.log(fileList)
            },
            handleFailed(error, file, fileList){
                this.showloading = false
                this.$Message.error({content:"文件上传失败", duration:5})
            },
            beforeUpload(){
                this.showloading = true
            }
        }
}
</script>

<style>
    .opbar {
        display: inline-flex;
        align-items: center;
    }

    .opbar button{
        margin:0px 4px;
    }

    .demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
</style>